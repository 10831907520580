<template>
    <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
        <div class="animated fadeIn">
            <b-row>
                <b-col lg="12">
                    <transition name="fade">
                        <b-card no-body>
                            <div slot="header">
                                <i class="fa fa-edit"></i> Add Place
                                <div class="card-header-actions">
                                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1><i class="icon-arrow-up"></i></b-link>
                                </div>
                            </div>
                            <b-collapse id="collapse1" visible>
                                <b-card-body>
                                    <b-row>
                                        <b-col sm="3">
                                            <TextBox v-model="item.name" placeholder="Name"
                                                     :addClass="{'is-invalid': $v.item.name.$error}">Name
                                            </TextBox>
                                        </b-col>
                                        <b-col sm="3">
                                            <TextBox v-model="item.phone" placeholder="Phone">Phone</TextBox>
                                        </b-col>
                                        <b-col sm="3">
                                            <TextBox v-model="cityName" placeholder="City">City</TextBox>
                                        </b-col>
                                        <b-col sm="3">
                                            <TextBox v-model="countryName" placeholder="Country">Country</TextBox>
                                        </b-col>
                                         <b-col sm="3">
                                            <TextBox v-model="longitude" placeholder="Longitude">Longitude</TextBox>
                                        </b-col>
                                        <b-col sm="3">
                                            <TextBox v-model="latitude" placeholder="Latitude">Latitude</TextBox>
                                        </b-col>
                                        <b-col sm="3">
                                            <SelectBox :items="categories" v-model="selectedCategory">Category</SelectBox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="3">
                                            <label>Delivery</label>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox"  v-model="item.delivery">
                                            </div>
                                        </b-col>
                                        <b-col sm="3">
                                            <label>Take Out</label>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="item.takeOut">
                                            </div>
                                        </b-col>
                                        <b-col sm="3">
                                            <label>Good For Groups</label>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="item.goodForGroups">
                                            </div>
                                        </b-col>
                                        <b-col sm="3">
                                            <label>Halal</label>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="item.halal">
                                            </div>
                                        </b-col>
                                        <b-col sm="3">
                                            <label>Gift</label>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="item.gift">
                                            </div>
                                        </b-col>
                                        <b-col sm="3">
                                            <label>Good For Breakfast</label>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="item.goodForBreakfast">
                                            </div>
                                        </b-col>
                                        <b-col sm="3">
                                            <label>Outdoor Sitting</label>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="item.outdoorSitting">
                                            </div>
                                        </b-col>
                                        <b-col sm="3">
                                            <label>Good For Lunch</label>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="item.goodForLunch">
                                            </div>
                                        </b-col>
                                        <b-col sm="3">
                                            <label>Takes Reservation</label>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="item.takesReservation">
                                            </div>
                                        </b-col>
                                        <b-col sm="3">
                                            <label>Good For Dinner</label>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="item.goodForDinner">
                                            </div>
                                        </b-col>
                                        <b-col sm="3">
                                            <label>Fri Wi-fi</label>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="item.friWiFi">
                                            </div>
                                        </b-col>
                                        <b-col sm="3">
                                            <label>Vegetarian Friendly</label>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="item.vegetarianFriendly">
                                            </div>
                                        </b-col>
                                        <b-col sm="3">
                                            <label>Live Music</label>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="item.liveMusic">
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="4">
                                            <table class="table table-bordered table-sm">
                                                <tbody>
                                                <tr>
                                                    <td>HashTag</td>
                                                    <td>Action</td>
                                                </tr>
                                                <tr v-for="(hashTag, index) in item.hashTags" :key="index">
                                                    <td>
                                                        <TextBox v-model="hashTag.tagName"/>
                                                    </td>
                                                    <td class="text-center"
                                                        style="vertical-align: middle; width: 70px;">
                                                        <b-button variant="success" type="button" size="sm"
                                                                  @click="addRow(index)">
                                                            <i class="fa fa-plus"></i>
                                                        </b-button>
                                                        <b-button variant="danger" type="button" size="sm"
                                                                  @click="removeRow(index)">
                                                            <i class="fa fa-window-close"></i>
                                                        </b-button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </b-col>
                                        <b-col sm="4">
                                            <h3>Logo</h3>
                                            <div class="profile-img-upload">
                                                <img v-if="logoUrl" :src="logoUrl" alt="img" style="width: 150px">
                                                <img v-else src="../../assets/img/no-image.png" alt="img" style="width: 150px">
                                                <input type="file" style="display: none"
                                                       id="logoImgUpload"
                                                       v-on:input="onLogoChoose">
                                                <label for="logoImgUpload">
                                            <span class="profile-img-upload-title">
                                                <i class="fa fa-camera mr-2"></i>
                                                <span style="cursor: pointer">Change</span>
                                            </span>
                                                </label>
                                            </div>
                                        </b-col>
                                        <b-col sm="4">
                                            <h3>Kvadrat Logo</h3>
                                            <div class="profile-img-upload">
                                                <img v-if="kvadratLogoUrl" :src="kvadratLogoUrl" alt="img" style="width: 150px">
                                                <img v-else src="../../assets/img/no-image.png" alt="img" style="width: 150px">
                                                <input type="file" style="display: none"
                                                       id="svgImgUpload"
                                                       v-on:input="onKvadratLogoChoose">
                                                <label for="svgImgUpload">
                                            <span class="profile-img-upload-title">
                                                <i class="fa fa-camera mr-2"></i>
                                                <span style="cursor: pointer">Change</span>
                                            </span>
                                                </label>
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <div class="d-flex justify-content-end">
                                        <div class="form-actions">
                                            <b-button right type="submit" variant="primary" @click="onSubmit">Save</b-button>
                                            <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/places/list'">Cancel</router-link>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </transition>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
  import {required} from 'vuelidate/lib/validators';

  export default {
    name: 'PlacesAddEditPage',
    data() {
      return {
        item: {
          name: '',
          hashTags: [{tagName: ''}]
        },
        categories: [],
        selectedCategory: null,
        cityName: '',
        countryName: '',
        longitude: '',
        latitude: '',
        logoUrl: '',
        kvadratLogoUrl: ''
      };
    },
    created() {
      this.loadRelatedData();
    },
    computed: {},
    methods: {
      async loadRelatedData() {
        let [categoryReq] = await Promise.all([
          this.axios.get('public/reference/categories')
        ]);
        if (categoryReq.data && categoryReq.data.data) {
          this.setSelectedItems(categoryReq.data.data, 'categories');
        }

      },
      setSelectedItems(items, code) {
        const mType = items.map(item => {
          return {text: item.name, value: item.id + ''};
        });
        mType.unshift({value: null, text: 'Select'});
        this[code] = mType;
      },
      onLogoChoose (event) {
        this.selectedFile = event.target.files[0]
        const formData = new FormData()
        formData.append('uploadFile', this.selectedFile)

        this.axios.post('public/upload/file',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        )
            .then((response) => {
              if (response.data && response.data.data) {
                this.item.logo = {id: response.data.data.id}
                this.logoUrl = response.data.data.imageURL
              }
            })
      },
      onKvadratLogoChoose (event) {
        this.selectedFile = event.target.files[0]
        const formData = new FormData()
        formData.append('uploadFile', this.selectedFile)

        this.axios.post('public/upload/file',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        )
            .then((response) => {
              if (response.data && response.data.data) {
                this.item.kvadratLogo = {id: response.data.data.id}
                this.kvadratLogoUrl = response.data.data.imageURL
              }
            })
      },
      onSubmit() {
        if (this.$v.item.$invalid) {
          this.$v.$touch();
          return;
        }
        if (this.selectedCategory) {
          const newCategoriesList = [];
          newCategoriesList.push({id: this.selectedCategory});
          this.item.categoryList = newCategoriesList;
        }
        if (this.cityName || this.countryName||this.cityName || this.countryName) {
          this.item.address = {
            cityName: this.cityName,
            countryName: this.countryName,
            longitude: this.longitude,
            latitude: this.latitude
          };
        }
        this.item.status='APPROVED'
        let axios = this.axios.post('place/item', this.item);
        axios.then((res) => {
          if (res.data) {
            this.$toastr.info('Successfully saved');
            this.$router.push('/places/list');
          } else {
            this.$toastr.error(res.data.message);
          }
        });
      },
      removeRow(tagIntex) {
        if (this.item.hashTags.length <= 1) {
          this.$toastr.error("You can't delete last row")
          return
        }
        const _compItem = this.item
        const itemIndex = _compItem.hashTags[tagIntex]
        if (itemIndex) {
          _compItem.hashTags.splice(tagIntex, 1)
          this.item = _compItem
        }
      },
      addRow(tagIntex) {
        const _compItem = this.item
        _compItem.hashTags = [
          ..._compItem.hashTags.slice(0, tagIntex + 1),
          Object.assign({}, this.filterItem),
          ..._compItem.hashTags.slice(tagIntex + 1)
        ]
        this.item = _compItem
      }
    },
    validations: {
      item: {
        name: {
          required
        }
      }
    }
  };
</script>

<style scoped>

</style>